import _ from 'lodash';

export default {
	namespace: true,

	state: {
		clientAccounts: {},
		currClientAccount: {}
	},

	mutations: {
		SET_ALL_CLIENT_ACCOUNTS(state, clientAccounts) {
			state.clientAccounts = clientAccounts;
		},
		SET_CURR_CLIENT_ACCOUNT(state, clientAccount) {
			state.currClientAccount = clientAccount
		}
	},

	actions: {
		setAllClientAccounts(vuexContext, clientAccounts) {
			vuexContext.commit('SET_ALL_CLIENT_ACCOUNTS', clientAccounts);
		},

		updateAllClientAccounts(vuexContext, newClientAccounts) {
			let clientAccounts = vuexContext.getters.clientAccounts;

			_.forEach(newClientAccounts, (value, key) => {
				clientAccounts[key] = value;
			});

			vuexContext.commit('SET_ALL_CLIENT_ACCOUNTS', clientAccounts);
		},

		setCurrentClientAccount(vuexContext, clientAccount) {
			vuexContext.commit('SET_CURR_CLIENT_ACCOUNT', clientAccount);
		},
	},

	getters: {
		clientAccounts(state) {
			if (!_.isEmpty(state.clientAccounts)) {
				return state.clientAccounts;
			}
			return {};
		},
		currClientAccount(state) {
			if (state.currClientAccount.accountNo) {
				return state.currClientAccount;
			}
			return {};
		},
	}
}
