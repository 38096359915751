import Vuex from 'vuex';
import createPersistedState from "vuex-persist-indexeddb";

// list of modules
import assetPoolDistributions from './modules/assetPoolDistributions';
import assetTypeCategories from './modules/assetTypeCategories';
import assetTypes from './modules/assetTypes';
import auth from './modules/auth';
import billingReports from './modules/billingReports';
import clientAccounts from './modules/clientAccounts';
import connections from './modules/connections';
import companies from './modules/companies';
import companyAssetPools from './modules/companyAssetPools';
import dispatchAlertReports from './modules/dispatchAlertReports';
import dispatches from './modules/dispatches';
import inventorySessions from './modules/inventorySessions';
import incidentTypes from './modules/incidentTypes';
import maintenances from './modules/maintenances';
import storageLocations from './modules/storageLocations';
import notifications from './modules/notifications';
import transportations from './modules/transportations';
import uoms from './modules/uoms';
import users from './modules/users';


const createStore = () => {
	return new Vuex.Store({
		modules: {
			assetPoolDistributions,
			assetTypeCategories,
			assetTypes,
			auth,
			billingReports,
			clientAccounts,
			companies,
			companyAssetPools,
			connections,
			dispatchAlertReports,
			dispatches,
			inventorySessions,
			incidentTypes,
			maintenances,
			storageLocations,
			notifications,
			transportations,
			uoms,
			users,
		},
		state: {},
		mutations: {},
		actions: {},
		getters: {},
		plugins: [createPersistedState()]
	});
};

export default createStore;
