import _ from 'lodash';

export default {
	namespace: true,

	state: {
		incidentTypes: {}
	},

	mutations: {
		SET_ALL_INCIDENT_TYPES(state, incidentTypes) {
			state.incidentTypes = incidentTypes;
		}
	},

	actions: {
		setAllIncidentTypes(vuexContext, incidentTypes) {
			vuexContext.commit('SET_ALL_INCIDENT_TYPES', incidentTypes);
		}
	},

	getters: {
		incidentTypes(state) {
			if (!_.isEmpty(state.incidentTypes)) {
				return state.incidentTypes;
			}
			return {};
		},
	}
}
