import _ from 'lodash';

export default {
	namespace: true,

	state: {
		billingReports: {},
		currBillingReport: {},
		billingReportParams: {},
	},

	mutations: {
		SET_ALL_BILLING_REPORT(state, billingReports) {
			state.billingReports = billingReports;
		},
		SET_CURR_BILLING_REPORT(state, billingReport) {
			state.currBillingReport = billingReport
		},
		SET_BILLING_REPORT_PARAMS(state, billingReportParams) {
			state.billingReportParams = billingReportParams
		},
	},

	actions: {
		setAllBillingReports(vuexContext, billingReports) {
			vuexContext.commit('SET_ALL_BILLING_REPORT', billingReports);
		},

		updateAllBillingReports(vuexContext, newBillingReports) {
			let billingReports = vuexContext.getters.billingReports;

			_.forEach(newBillingReports, (value, key) => {
				billingReports[key] = value;
			});

			vuexContext.commit('SET_ALL_BILLING_REPORT', billingReports);
		},

		setCurrentBillingReport(vuexContext, billingReport) {
			vuexContext.commit('SET_CURR_BILLING_REPORT', billingReport);
		},

		setBillingReportParams(vuexContext, params) {
			vuexContext.commit('SET_BILLING_REPORT_PARAMS', params);
		}
	},

	getters: {
		billingReports(state) {
			if (!_.isEmpty(state.billingReports)) {
				return state.billingReports;
			}
			return {};
		},
		currBillingReport(state) {
			if (state.currBillingReport.billingReportNo) {
				return state.currBillingReport;
			}
			return {};
		},
		billingReportParams(state) {
			if (state.billingReportParams.allCompaniesObj) {
				return state.billingReportParams;
			}
			return {};
		},
	}
}
