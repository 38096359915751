import _ from 'lodash';

export default {
	namespace: true,

	state: {
		inventorySessions: {},
		currInventorySession: {},
		inventorySessionParams: {}
	},

	mutations: {
		SET_ALL_INVENTORY_SESSIONS(state, inventorySessions) {
			state.inventorySessions = inventorySessions;
		},
		SET_CURR_INVENTORY_SESSION(state, inventorySession) {
			state.currInventorySession = inventorySession;
		},
		SET_INVENTORY_SESSION_PARAMS(state, inventorySessionParams) {
			state.inventorySessionParams = inventorySessionParams;
		}
	},

	actions: {
		setAllInventorySessions(vuexContext, inventorySessions) {
			vuexContext.commit('SET_ALL_INVENTORY_SESSIONS', inventorySessions);
		},
		updateAllInventorySessions(vuexContext, newInventorySessions) {
			let inventorySessions = vuexContext.getters.inventorySessions;

			_.forEach(newInventorySessions, (value, key) => {
				inventorySessions[key] = value;
			});

			vuexContext.commit('SET_ALL_INVENTORY_SESSIONS', inventorySessions);
		},

		setCurrentInventorySession(vuexContext, inventorySession) {
			vuexContext.commit('SET_CURR_INVENTORY_SESSION', inventorySession);
		},

		setInventorySessionParams(vuexContext, params) {
			vuexContext.commit('SET_INVENTORY_SESSION_PARAMS', params);
		}
	},

	getters: {
		inventorySessions(state) {
			if (!_.isEmpty(state.inventorySessions)) {
				return state.inventorySessions;
			}
			return {};
		},
		inventorySessionParams(state) {
			if (state.inventorySessionParams.allCompaniesObj) {
				return state.inventorySessionParams;
			}
			return {};
		},
		currInventorySession(state) {
			if (state.currInventorySession.sessionId) {
				return state.currInventorySession;
			}
			return {};
		}
	}
};
