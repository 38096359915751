import config from '@/config/env-constants';
import _ from 'lodash';


function allowManagerAccount(user, users) {
	let isManager = user.type === config.managerRole;
	if (!isManager) {
		return true;
	}

	let activeManagers = _.filter(users, o => {
		return o.isActive === "true" &&
			user.companyId === o.companyId &&
			o.type === config.managerRole;
	});

	const isManagerLimitExceeded = activeManagers.length >= 3;
	const isPartOfExistingManagers = isManager && activeManagers.length > 0 && _.find(activeManagers, { 'id': user.id });

	return !isManagerLimitExceeded || isPartOfExistingManagers;
}

function exceedMaximum(company, users, currUser) {
	let maxNo = company.noOfUsers ? company.noOfUsers : 0;
	if (!_.isEmpty(users)) {
		let activeUsers = _.filter(users, o => {
			return o.isActive === 'true' && company.id === o.companyId;
		});

		let activeUserIds = _.map(activeUsers, 'id');
		if (currUser && activeUserIds.includes(currUser.id)) {
			// skip if the currUser is already part of current active users
			return false;
		} else {
			return _.size(activeUsers) >= maxNo;
		}
	}
	return false;
}

function isSuperAdmin(userEmailAddress) {
	return userEmailAddress === config.adminAccount.TAWI_SUPPORT;
}

function getDefaultUserObj() {
	return {
		firstName: '',
		middleName: '',
		lastName: '',
		company: '',
		companyId: '',
		position: '',
		employeeNo: '',
		type: null,
		contactNo: '',
		emailAddress: '',
		password: '',
		confirmPassword: '',
		driversLicense: {
			licenseNo: '',
			image: {},
		},
		isActive: 'true',
		hasNotif: false,
		has2WayAuth: false,
		hasGeofencing: false,
		isNewUser: true,
		companyAccess: [],
		dateCreated: null,
		createdBy: '',
		dateUpdated: null,
		updatedBy: '',
	};
}

function getDefaultCompanyAccess(user, allCompaniesObj) {
	let companyAccess = [];

	// default access
	let currCompanyObj = allCompaniesObj[user.companyId];
	companyAccess.push({
		id: currCompanyObj.id,
		name: currCompanyObj.name,
		description: currCompanyObj.description
	});

	return companyAccess;
}

function cleanupFields(user) {
	let cleanedObj = { ...user };

	delete cleanedObj['Employee No'];
	delete cleanedObj['status'];
    delete cleanedObj['Date Created'];
    delete cleanedObj['Date Updated'];
	delete cleanedObj['_showDetails'];

	return cleanedObj;
}

function hasCompanyAccess(companiesObj, companyId) {
	let companyObj = _.find(companiesObj, (o) => { return o.id === companyId });
	return companyObj && !_.isEmpty(companyObj);
}

function hasExistingLicenseNo(allUsersObj, currUser) {
	let hasExisting = false;
	let usersArr = Object.values(allUsersObj);
	for (const user of usersArr) {
		if (user.id != currUser.id &&
			user.driversLicense &&
			user.driversLicense.licenseNo === currUser.driversLicense.licenseNo) {
			hasExisting = true;
			break;
		}
	}
	return hasExisting;
}

function getUserDisplay(usersObj, userId) {
	let userObj = usersObj[userId];
	if (!_.isEmpty(userObj)) {
		return userObj.firstName + ' ' + userObj.lastName + ' (' + userId + ')';
	}
	return userId;
}

function getUserFullName(usersObj, userId) {
	let userObj = usersObj[userId];
	if (!_.isEmpty(userObj)) {
		return userObj.firstName + ' ' + userObj.lastName;
	}
	return userId;
}

function getUserAbbreviatedName(usersObj, userId) {
	let userObj = usersObj[userId];
	if (!_.isEmpty(userObj)) {
		return userObj.firstName[0] + '. ' + userObj.lastName;
	}
	return userId;
}

export const UserUtil = {
	allowManagerAccount,
	exceedMaximum,
	isSuperAdmin,
	getDefaultUserObj,
	getDefaultCompanyAccess,
	cleanupFields,
	hasCompanyAccess,
	hasExistingLicenseNo,
	getUserDisplay,
	getUserFullName,
	getUserAbbreviatedName
}
