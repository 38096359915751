/* eslint-disable no-useless-escape */
export default {
	currEnv: 'prod',
	timeout: 1000,
	maxLogs: 50,
	maxInQuery: 10,
	maxAssetTypePerDispatch: 30,
	view: {
		ADMIN: "Admin",
		COMPANY: "Company"
	},
	batchStatus: {
		IN_PRODUCTION: {
			name: "In Production",
			nextStatus: "For Checking",
			actionLabel: "Check"
		},
		IN_QC: {
			name: "In QC",
			nextStatus: "For Endorsement",
			actionLabel: "Endorse"
		},
		IN_WAREHOUSE: {
			name: "In Warehouse",
			nextStatus: "Done",
			actionLabel: ""
		},
		CANCELLED: {
			name: "Cancelled",
			nextStatus: "",
			actionLabel: ""
		},
	},
	requestSource: {
		CMS: 'CMS',
		DISPATCH: 'Dispatch',
		TRANSPORT: 'Transport',
	},
	firebaseAuthLink: 'https://www.googleapis.com/identitytoolkit/v3/relyingparty/verifyPassword?key=',
	adminAccount: {
		TAWI_SUPPORT: 'support@tawitech.ph'
	},
	superAdminRole: 'Super Admin',
	managerRole: 'Manager',
	supervisorRole: 'Supervisor',
	viewerRole: 'Viewer',
	scannerRole: 'Scanner',
	companyNameRegex: /^[ÑñA-Za-z0-9&",.! '-]{2,100}$/,
	locationNameRegex: /^[ÑñA-Za-z0-9&",.! '-]{2,100}$/,
	nameRegex: /^([ÑñA-Za-z- .']{1,30})$/,
	positionRegex: /^[ÑñA-Za-z0-9&,. '-]{2,60}$/,
	fullNameRegex: /^([ÑñA-Za-z- .']{1,100})$/,
	contactNoRegex: /\(\+63\s?\d{1,2}\)\s?\d{4}\s?\d{4}|^[+]639[0-9]{9}$|^(\([0-9]{2,3}\)\s?[0-9]{3}[-\s][0-9]{4}\/)?\([0-9]{2,3}\)[-\s]?[0-9]{3,4}[-\s]?[0-9]{4}$/,
	emailRegex: /^[\w.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
	employeeNoRegex: /^([0-9]{1,12})$/,
	passwordRegex: /[A-Za-z0-9<>().,"'=:;@$%*?!&_\-+|]{8,}/,
	remarksRegex: /^[ÑñA-Za-z0-9\\s&'"\-,.\/():;!*[\] ]{1,200}$/,
	addressRegex: /^(?=.*[ÑñA-Za-z ])[ÑñA-Za-z\d\s(),.#'-]+$/,
	plateNoRegex: /^(?:[A-Z]{3,4}-\d{3,4}|[A-Z\d]{2}-[A-Z\d]{4})$/,
	brandRegex: /^[A-Za-z0-9]{1,5}$/,
	branchCodeRegex: /^[ÑA-Za-z0-9][ñA-Za-z0-9\s&'\-,.\/]{1,10}$/,
	passwordErrorMsg: 'Password must have at least eight (8) characters and should contain at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character',
	accountNoRegex: /^[A-Za-z0-9-().+ ]{6,100}$/,
	assetTypeNameRegex: /^([A-Za-z0-9\s\-:()\\/,.*]{1,50})$/,
	maxBatchSize: 400,
	companyTypes: [{
		value: null,
		text: ' - Please select - '
	},
	{
		value: 'Asset User',
		text: 'Asset User'
	},
	{
		value: 'Asset Service Provider',
		text: 'Asset Service Provider'
	}
	],
	assetStatus: [{
		value: null,
		text: ' - Please select - '
	},
	{
		value: 'Inactive',
		text: 'Inactive'
	},
	{
		value: 'Stored',
		text: 'Stored'
	}, {
		value: 'In-Transit',
		text: 'In-Transit'
	}, {
		value: 'Lost',
		text: 'Lost'
	}
	],
	changeLocationPurpose: [{
		value: null,
		text: ' - Please select - '
	},
	{
		value: 'Wrong Input',
		text: 'Wrong Input'
	},
	{
		value: 'Unscanned Assets',
		text: 'Unscanned Assets'
	}, {
		value: 'Others',
		text: 'Others'
	}
	],
	dispatchStatus: [
		{
			value: null,
			text: ' - Please select - '
		},
		{
			value: 'Draft',
			text: 'Draft'
		},
		{
			value: 'In-Transit',
			text: 'In-Transit'
		},
		{
			value: 'Receiving',
			text: 'Receiving'
		},
		{
			value: 'Received',
			text: 'Received'
		},
		{
			value: 'Rejected',
			text: 'Rejected'
		},
		{
			value: 'Cancelled',
			text: 'Cancelled'
		},
	],
	receiptStatus: [
		{
			value: null,
			text: ' - Please select - '
		},
		{
			value: 'In-Transit',
			text: 'In-Transit'
		},
		{
			value: 'Receiving',
			text: 'Receiving'
		},
		{
			value: 'Received',
			text: 'Received'
		},
		{
			value: 'Rejected',
			text: 'Rejected'
		},
		{
			value: 'Cancelled',
			text: 'Cancelled'
		},
	],
	inventorySessionStatus: [
		{
			value: null,
			text: ' - Please select - '
		},
		{
			value: 'On-Going',
			text: 'On-Going'
		},
		{
			value: 'Done',
			text: 'Done'
		},
		{
			value: 'Cancelled',
			text: 'Cancelled'
		}
	],
	accountStatus: [
		{
			value: null,
			text: ' - Please select - '
		},
		{
			value: 'Inactive',
			text: 'Inactive'
		},
		{
			value: 'Active',
			text: 'Active'
		},
		{
			value: 'Terminated',
			text: 'Terminated'
		},
		{
			value: 'Expired',
			text: 'Expired'
		}
	],
	billingReportStatus: [
		{
			value: null,
			text: ' - Please select - '
		},
		{
			value: 'Approved',
			text: 'Approved'
		},
		{
			value: 'Cancelled',
			text: 'Cancelled'
		},
		{
			value: 'Pending',
			text: 'Pending'
		},
		{
			value: 'Rejected',
			text: 'Rejected'
		}
	],
	assetHistoryOperation: {
		CREATE_NEW_ASSET: 'CREATE_NEW_ASSET',
		ACTIVATE_ASSET: 'ACTIVATE_ASSET',
		CHANGE_ASSET_LOCATION: 'CHANGE_ASSET_LOCATION',
		MARK_ASSET_AS_LOST: 'MARK_ASSET_AS_LOST',
		DISPATCH_ASSET: 'DISPATCH_ASSET',
		CANCEL_DISPATCH_ASSET: 'CANCEL_DISPATCH_ASSET',
		RECEIVE_ASSET: 'RECEIVE_ASSET',
		IMPORT_ASSET: 'IMPORT_ASSET',
		ASSET_INVENTORY: 'ASSET_INVENTORY'
	},
	assetTagging: {
		DEFAULT: 'Default',
		CUSTOM: 'Custom'
	},
	assetIssuanceStatus: {
		ON_GOING: 'On-Going',
		RETURNED: 'Returned',
		CANCELLED: 'Cancelled'
	},
	statusOptions: [
		{ text: 'Active', value: "true" },
		{ text: 'Inactive', value: "false" },
	],
	deviceTypeOptions: [
		{ text: ' - Please select - ', value: null },
		{ text: 'Mobile', value: "mobile" },
		{ text: 'Web', value: "web" },
	],
	statusOptionsWithDefault: [
		{ text: ' - Please select - ', value: null },
		{ text: 'Active', value: "true" },
		{ text: 'Inactive', value: "false" },
	],
	requestStatusOptions: [
		{ text: ' - Please select - ', value: null },
		{ text: 'Open', value: 'Open' },
		{ text: 'Approved', value: 'Approved' },
		{ text: 'Rejected', value: 'Rejected' },
	],
	assetTaggingOptions: [
		{ text: 'Default', value: "Default" },
		{ text: 'Custom', value: "Custom" },
	],
	dropdownDefaultValue: {
		value: null,
		text: ' - Please select - '
	},
	companyDefaultValue: {
		id: null,
		isActive: null,
		name: ' - Please select - ',
		type: null,
		brand: null,
		text: ' - Please select - '
	},
	storageLocationDefaultValue: {
		id: null,
		name: ' - Please select - ',
		geoaddress: null,
		companyId: null,
		text: ' - Please select - '
	},
	transportationDefaultValue: {
		id: null,
		name: ' - Please select - ',
		company: null,
		companyId: null,
		text: ' - Please select -',
		hasCheckingInterval: false,
		checkingInterval: 0
	},
	transportationOthersValue: {
		id: null,
		name: ' - Others - ',
		company: null,
		companyId: null,
		text: ' - Please select -',
		hasCheckingInterval: false,
		checkingInterval: 0
	},
	assetTypeDefaultValue: {
		id: null,
		name: ' - Please select - ',
		origin: null,
		originId: null,
		text: ' - Please select - '
	},
	dispatchDefaultValue: {
		id: null,
		name: ' - Please select - ',
		dispatchId: null,
		text: ' - Please select - '
	},
	assetTypeCodeDefaultValue: {
		id: null,
		name: ' - Please select - ',
		prefix: null,
		text: ' - Please select - '
	},
	userDefaultValue: {
		id: null,
		name: ' - Please select - ',
		companyId: null,
		type: null,
		text: ' - Please select - '
	},
	driverDefaultValue: {
		id: null,
		name: ' - Please select - ',
		company: null,
		companyId: null,
		assistants: null,
		isAccountable: false,
		license: {}
	},
	incidentTypeDefaultValue: {
		value: null,
		text: ' - Please select - ',
	},
	driverOtherValue: {
		id: null,
		name: ' - Others - ',
		company: null,
		companyId: null,
		assistants: null,
		isAccountable: false,
		license: {}
	},
	dispatchCreationSource: {
		DISPATCH: 'Dispatch',
		RECEIPT: 'Receipt',
	},
	dispatchInputAssetLogDefaultValue: {
		dispatch: { scan: [], manual: [], auto: [] },
		receipt: { scan: [], manual: [], auto: [] }
	},
	inputAssetLogDefaultValue: { scan: [], manual: [], auto: [] },
	notificationType: {
		LOCKED_ACCOUNT: 'LOCKED_ACCOUNT',
		NEW_COMPANY: 'NEW_COMPANY',
		DISPATCH_DEPLOYED: 'DISPATCH_DEPLOYED',
		DISPATCH_RECEIVED: 'DISPATCH_RECEIVED',
		DISPATCH_IN_TRANSIT: 'DISPATCH_IN_TRANSIT',
		CHANGE_LOCATION: 'CHANGE_LOCATION',
		INCIDENT_REPORT: 'INCIDENT_REPORT'
	},
	permissionsDefaultValues: {
		cms: true,
		inventory: false,
		dispatch: true,
		transport: false,
		maintenance: false,
		billing: false,
	},
	dispatchTransportDefaultValue: {
		plateNo: '',
		company: '',
		companyId: '',
		pickUpPoint: {
			latitude: 0.0,
			longitude: 0.0
		},
		datePickUp: 0,
		dropOffPoint: {
			latitude: 0.0,
			longitude: 0.0
		},
		dateDropOff: 0,
		estimatedTravelTime: 0,
		actualTravelTime: 0,
		hasCheckingInterval: false,
		checkingInterval: 0,
	},
	dispatchDriverDefaultValue: {
		userId: '',
		name: '',
		company: '',
		companyId: '',
		assistants: '',
		isAccountable: false,
		license: {}
	},
	cameraDefaultValues: {
		top: false,
		back: true
	},
	clientAccountDefaultValue: {
		id: null,
		accountNo: null,
		status: null,
		name: ' - Please select - ',
	},
	changeRequestDefault: {
		requestId: '',
		requestedBy: '',
		dateRequested: 0,
		requestReason: '',
		approvedBy: '',
		dateApproved: 0,
		rejectedBy: '',
		dateRejected: 0,
		status: 'For Approval',
		old: null,
		new: null,
	},
	classificationDefault: {
		collectionName: '',
		collectionId: '',
		dispatchId: '',
		assetTypeId: '',
		assetType: '',
		area: null,
		conditions: [],
		documents: [],
		remarks: '',
		isCompleted: false,
	},

	rentFrequencyTypes: [
		{ value: '', text: ' - Please select - ' },
		{ value: 'Per Hour', text: 'Per Hour' },
		{ value: 'Per Day', text: 'Per Day' },
	],
	contractPeriods: [
		{ value: null, text: ' - Please select - ' },
		{ value: '1 Month', text: '1 Month' },
		{ value: '3 Months', text: '3 Months' },
		{ value: '6 Months', text: '6 Months' },
		{ value: '1 Year', text: '1 Year' },
		{ value: '2 Years', text: '2 Years' },
		{ value: '5 Years', text: '5 Years' },
		{ value: ' - Others - ', text: ' - Others - ' }
	],
	monthTypes: [
		{ value: null, text: ' - Please select - ' },
		{ value: 'January', text: 'January' },
		{ value: 'February', text: 'February' },
		{ value: 'March', text: 'March' },
		{ value: 'April', text: 'April' },
		{ value: 'May', text: 'May' },
		{ value: 'June', text: 'June' },
		{ value: 'July', text: 'July' },
		{ value: 'August', text: 'August' },
		{ value: 'September', text: 'September' },
		{ value: 'October', text: 'October' },
		{ value: 'November', text: 'November' },
		{ value: 'December', text: 'December' },
	],
	contractMetrics: [
		{ value: null, text: ' - Please select - ' },
		{ value: 'Month(s)', text: 'Month(s)' },
		{ value: 'Year(s)', text: 'Year(s)' },
	],
	billingFrequency: [
		{ value: null, text: ' - Please select - ' },
		{ value: 'Daily', text: 'Daily' },
		{ value: 'Monthly', text: 'Monthly' },
		{ value: 'Yearly', text: 'Yearly' }
	],
	truckerTypes: [
		{ value: null, text: ' - Please select - ' },
		{ value: 'In-house', text: 'In-house' },
		{ value: 'Outsourced', text: 'Outsourced' }
	],
	handlingTypes: [
		{ value: null, text: ' - Please select - ' },
		{ value: 'For Repair', text: 'For Repair' },
		{ value: 'For Disposal', text: 'For Disposal' },
		{ value: 'For Cleaning', text: 'For Cleaning' },
		{ value: 'No Action Needed', text: 'No Action Needed' },
	],
	maxQRCodeStrLength: 30,
	areaRadiusDefaultValue: 100,
	dateFormatOptions: {
		year: 'numeric',
		month: 'short',
		day: '2-digit',
		weekday: 'short',
	}
};
